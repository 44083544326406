<template>
  <LazyAtomsButton
    :ref="() => observer"
    :to="{ id }"
    class="item"
    :class="{ intersected }"
    plain
  >
    <LazyAtomsImage
      v-if="image"
      :image="image"
      class="item-image"
      :options="{ width: 10 * 16, height: 10 * 16 }"
      :io="{ intersected }"
      :zoom="false"
    />
    <article class="item-content">
      <LazyAtomsHeading
        element="h4"
        class="heading item-title"
        v-text="title"
      />
      <LazyAtomsDate
        v-if="type === 'article' && datePublished"
        class="item-date"
        icon="pencil-square"
        :date="datePublished"
      />
      <p v-text="description" />
      <LazyAtomsTags
        v-if="tags && tags.length"
        class="item-tags"
        :buttons="false"
        :tags="tags"
      />
      <span class="item-action"
        >Read more <AtomsIcon name="arrow-right"
      /></span>
    </article>
    <span title="Read more" class="item-column-action"
      ><AtomsIcon name="arrow-right"
    /></span>
  </LazyAtomsButton>
</template>

<script lang="ts" setup>
defineProps({
  id: String,
  image: Object,
  title: String,
  description: String,
  quote: String,
  slug: String,
  tags: {
    type: Array as () => string[],
    default: () => [],
  },
  type: String,
  datePublished: String,
})

const { observer, intersected } = useIntersectionObserver({ triggerOnce: true })
</script>

<style lang="scss">
.item {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: stretch;
  max-width: $container-width;

  &.intersected .item-content {
    animation-play-state: running;
  }

  @include media('<tablet') {
    flex-flow: column;
  }

  .item {
    &-content {
      display: flex;
      flex: 1 1 auto;
      flex-flow: column;
      font-weight: 400;
      width: 45ch;
      max-width: 100%;

      @include media('<=tablet') {
        width: 100%;
      }
    }
    &-title {
    }
    &-date {
      margin: -0.6rem 0 0.8rem;
      font-size: 1rem;
    }
    &-image {
      display: flex;
      min-width: unset;
      width: 10rem;
      height: 10rem;
      margin-right: max($lg, 3vw);
      border-radius: var(--br-items-lg);
    }
    &-column-action,
    &-action {
      transition: transition($transition, (transform, opacity));
    }
    &-action {
      display: flex;
      align-self: flex-end;
      font-family: var(--font-primary);
      font-style: italic;
      font-size: 1rem;
      margin-top: 0 - $md;
      align-items: center;

      .icon {
        margin-left: $sm;
      }
    }
    &-column-action {
      display: none;
    }
  }

  &:hover,
  &:focus {
    .item-column-action,
    .item-action {
      transform: translateX(1rem);
    }
  }

  &:active {
    .item-column-action,
    .item-action {
      transform: translateX(2rem);
      opacity: 0;
    }
  }
}
</style>
